import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from "gatsby"
/* App imports */
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import PostList from '../../components/post-list'
import Config from '../../../config'
import "./Projects.css";
import ProjectsImg from "./ProjectsImg";
import { projectsHeader } from "../../portfolio.js";
import TopButton from "../../components/topButton/TopButton";

import { Fade } from "react-reveal";
import { theme } from "../../theme.js"

export const query = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/index.md$/" } }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            tags
            date(formatString: "MMMM DD, YYYY")
            excerpt
            cover {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
            }
          }
        }
      }
    }
  }
`


const IndexPage = ({ data }) => (
  <StaticQuery
    query={query}
    render={data => (
      <Layout>
        <SEO title="Projects" description={Config.siteDescription} path="" />
        <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div className="projects-heading-div">
              <div className="projects-heading-img-div">
                {/* <img
											src={require(`../../assests/images/${projectsHeader["avatar_image_path"]}`)}
											alt=""
										/> */}
                <ProjectsImg style={{ width: "20%" }} theme={theme} />
              </div>
              <div className="projects-heading-text-div">
                <h1
                  className="projects-heading-text"
                  style={{ color: theme.text2 }}
                >
                  {projectsHeader.title}
                </h1>
                <p
                  className="projects-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {projectsHeader["description"]}
                </p>
              </div>
            </div>

            <PostList posts={data.allMarkdownRemark.edges} />
          </Fade>
        </div>
      </Layout>
    )}
  />
)

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }).isRequired,
  }).isRequired,
}


export default IndexPage